.user-image {
  height: 35px;
}

a.profilelink {
  color: rgba(36, 28, 21, 0.65);
  font-size: 0.9rem;
  text-decoration: none;
}

span.prim-link,
a.prim-link {
  color: rgba(36, 28, 21, 0.65) !important;
  transition: all 0.2s ease-in-out 0s !important;
}

span.prim-link:hover,
a.prim-link:hover {
  color: #DC3644 !important;
}

a.prim-link.active {
  color: #DC3644 !important;
  font-weight: bold;
}

.active {
  position: relative;
}

a.nav-link.active:after {
  content: "";
  position: absolute;
  top: 3.6rem;
  left: 0px;
  border-top: 3px solid #DC3644;
  width: 100%;
  cursor: auto;
}

a.profiledropdownitem,
span.profiledropdownitem {
  color: rgba(36, 28, 21, 0.65);
  font-size: 1rem;
  padding: 1rem;
  transition: all 0.2s ease-in-out 0s;
}

.profile-dropdown {
  margin-top: 44px;
  border-radius: 0 0 0.25rem 0.25rem;
  z-index: 1;
  line-height: 24px;
  padding: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  min-width: 275px;
}

a.profile-dropdown {
  color: rgba(36, 28, 21, 0.65);
  font-size: 0.9rem;
}

#autoThumbnailParent {
  width: 40px;
  height: 40px;
  border-radius: 32px;
  background: #f4f8fb;
}

/* #autoThumbnailParent::after{
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
    left: 0;
    top: 0;
}
#autoThumbnailParent:hover::after {
    opacity: 1;
} */
#autoThumbnailChild {
  text-align: center;
  color: #0089ff;
  background-size: 32px 32px;
  line-height: 40px;
  font-size: 1rem;
  font-weight: bold;
}

/* Removes the dropdown arrow from the header memu */
.profilelink::after {
  display: none;
}

.profile-dropdown-header-item {
  pointer-events: none;
}

.profile-dropdown-header-item:hover {
  background-color: #fff !important;
}

.dropdown-divider {
  margin: 0;
}

.btn-primary-cmill {
  background-color: #157FFC;
  border-color: #157FFC;
  color: white;
  font-size: 15px;
  /*padding: 12px 16px !important;*/
  border-right: 4px;
  transition: all 80ms ease-in-out 0s;
}

.btn-primary-cmill:hover {
  color: white;
  /* background-color: rgb(10, 70, 228);
  border-color: rgb(10, 70, 228); */
  transition: all 80ms ease-in-out 0s;
}
.nav-store-icon{
  font-size: 16px;
}