.menu-detail-page-order-summary-table {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.menu-detail-page-order-summary-table::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.orderDetailsTableHeader {
  min-width: 120px !important;
}
.orderDetailsTableQty {
  width: 50px !important;
}
.long-table-text {
  max-width: 150px;
  word-break: break-word;
}
.group-overflow {
  max-width: 100px;
  word-break: break-all !important;
}