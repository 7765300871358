body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro",sans-serif;
  font-family: 'Roboto', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.autocomplete-dropdown-container {
  /* position: absolute; */
  z-index: 1;
}

.saveBtn {
  min-width: 50px;
}

.normalBtn {
  min-width: 170px;
}

.mr-41 {
  margin-right: 41px;
}

/* To handle the Focus Issue with Package (react-pure-carousel) */
.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}
/* To Fix the issue with the Package (react-modal) Input Tabbing Issue (https://github.com/reactjs/react-modal/issues/782#issuecomment-796844745)  */
.ReactModalPortal {
  position: absolute;
  min-height: .5px;
}

.Mui-focused{
  outline-color: transparent;
}