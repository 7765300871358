.user-custom-header{
  border-bottom: none !important;
  border: none;
}
.DeliveryOrderDetailsSidebar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.DeliveryOrderDetailsSidebar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.scheduler-input-time > .react-datepicker-wrapper {
  width: 100% !important;
}