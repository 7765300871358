@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
  }
  .modal-dialog-long {
    max-width: 800px;
  }
}

.modal-dialog {
  margin: 0;
}

.modal-body {
  max-height: 79vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* .card-quantity-pill {
  background-color: #dc3545;
  width: 25px;
  position: absolute;
  left: -13px;
  top: -13px;
  color: white;
  border-radius: 50%;
  text-align: center;
} */

/* .alertOpen {
  transform: rotate(180deg);
} */

.w3-animate-top {
  position: relative;
  animation: animatetop 0.7s;
}

@keyframes animatetop {
  from {
    top: -100px;
  }

  to {
    top: 0;
  }
}

.w3-animate-bottom {
  position: relative;
  animation: animatebottom 0.7s;
}

@keyframes animatebottom {
  from {
    bottom: 0px;
  }

  to {
    bottom: 100px;
  }
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 8px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}
 
/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #D2D2D6; 
  border-radius: 5px;
  min-height: 25px;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {

}

.tooltip_rounded{
  border-radius: 10px !important;
}

.rc-tooltip{
  opacity: 1;
}

.tooltip_wrapper{
  background: transparent radial-gradient(closest-side at 69% 20%, #FFFFFF 43%, #E6E6E6E5 0%, #FFFFFF 43%, #FFFFFF00 22%, #3C3C3C90 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.92;
  position: fixed;
  z-index: 7;
  inset: 0;
  width: 100%;
}
@media only screen and (min-width: 1440px) {
  .tooltip_wrapper{
    background: transparent radial-gradient(closest-side at 60% 34%, #FFFFFF 29%, #E6E6E6E5 5%, #FFFFFF 25%, #FFFFFF00 0%, #3C3C3C90 76%) 32% 13% no-repeat padding-box;
    top: -26% !important;
    width: 100%;
    right: 0%;
    bottom: 0;
    }
  }

.tooltip_wrapper_user{
  background: transparent radial-gradient(closest-side at 73% 15%, #FFFFFF 0%, #E6E6E6E5 0%, #FFFFFF 13%, #FFFFFF00 22%, #3C3C3C90 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.92;
  position: fixed;
  z-index: 7;
  inset: 0;
  width: 100%;
}
.pills-position{
  left: 0px;
  top: 2px;
  z-index: 4;
  }
  .no-hover-effects:hover {
    font-weight: 800 !important;
  }
  .hover-element-effects:hover p {
    font-weight: 800 !important;
  }
  .prefered {
    position: relative;
  }
  .prefered::after {
    content: "";
    position: absolute;
    margin: 4px;
    right: -15px;
    width: 3px;
    height: 40px; 
    background-color: #A6B4BA;
  }