body {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  height: 100%;
  min-height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: flex-start !important;
}

.login-alert {
  width: 75%;
  margin: 20px auto;
}

.login_banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  height: 130px;
}

.signup_subtext {
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.form-signin {
  width: 80%;
  padding-top: 15px;
  margin: auto;
  text-align: left;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin label {
  font-weight: 400;
  color: var(--gray-600);
}

/* .form-signin .form-control:focus {
  z-index: 2;
} */

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-cuisine {
  border-radius: 24.2px;
  margin: 10px;
}

i.password-icon {
  position: absolute;
  right: 4%;
  top: 32%;
  height: 20px;
  width: 20px;
  /* z-index: 2; */
}
.modal-close{
  color: #212529;
  opacity: 100%;
}
/* Login modal button customize */
.login-btn{
  height: 2.5rem;
  width: 2.5rem;
  aspect-ratio: 1/1;
  background-color: var(--pink-100);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-login-icon{
  height: 1rem;
  width: 1rem;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color:var(--gray-100);
}
.login-button-hover:hover{
  background-color: var(--white);
  border-color: #C91526;
  color: #C91526;
}

.custom-google-signin>div>div>div {
  padding: 24px 16px;
  font-size: 16px;
}
.login-container {
  background-color: #1a73e8;
  color: white;
  border-radius: 5px;
  min-height: 48px;
  cursor: pointer;
}
.login-container:hover {
  box-shadow: none;
  background-color: #5595ee;
  outline: none;
  transition: all 0.2s;
}