.mobile-calendar .icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

/* :root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
} */

/* GENERAL */

/* * {
  box-sizing: border-box;
} */

.mobile-calendar body {
  /* font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif; */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}

.mobile-calendar header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

.mobile-calendar header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

.mobile-calendar header #logo .icon {
  padding-right: 0.25em;
}

.mobile-calendar main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.mobile-calendar .row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.mobile-calendar .row-middle {
  align-items: center;
}

.mobile-calendar .col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0;
}

.mobile-calendar .col-start {
  justify-content: flex-start;
  text-align: left;
}

.mobile-calendar .col-center {
  justify-content: center;
  text-align: center;
}

.mobile-calendar .col-end {
  justify-content: flex-end;
  text-align: right;
}

.mobile-calendar {
  background: white;
  color: var(--gray);
}
.mobile-calendar .single-date {
  text-align: center;
}
.mobile-calendar .selected-day {
  background-color: #0089ff26;
  /* border-radius: 50%; */
}
.mobile-calendar .single-date p:first-child {
  margin: 0;
}
.mobile-calendar .single-date p:last-child {
  width: 35px;
  height: 35px;
  line-height: 37px;
  border-radius: 50%;
  margin: 5px auto;
}

.mobile-calendar .delivery-present::after {
  content: "\A" !important;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--danger) !important;
  display: inline-block;
  position: absolute;
  text-align: center;
  top: 5px !important;
  left: calc(50% - 2px);
}
.mobile-calendar .order-present::after {
  content: "\f00c" !important;
  background: #ffffff !important;
  width: 0px;
  font-size: 12px;
  color: #28a745;
  font-family: "Font Awesome 6 pro";
  font-weight: 900;
  position: absolute;
  top: 0px !important;
  left: calc(50% - 4px);
}
.mobile-calendar .order-present::after {
  top: -11px;
}

.mobile-calendar .calender_line_height {
  line-height: 37px;
}

.mobile-calendar .calendar-selected-day {
  background-color: #0089ff26;
  border-radius: 50%;
}
.mobile-calendar .all-days {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-calendar .icon {
  cursor: pointer;
}
