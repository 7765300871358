:root {
    --primary: #0089ff;
    --danger: #dc3545;
    --gray: #8b9898;
    --gray2: #00000066;
    --ash-gray:#F7F7F7;
    --black: #000000;
    --white: #ffffff;
  }
  

.order-popup-modal-header{
    padding: 20px 21px 10px 30px;
  }

 .order-popup-custom-badges{ 
    font-weight: 600;
    font-stretch: normal;
    font-size: var(--base);
    line-height: 22px;
    font-family: "Source Sans Pro";
    letter-spacing: 0px;
    color: #212529;
    opacity: 1;
}

@media only screen and (max-width: 845px){
    .order-poup-header-wrapper{
        overflow-y: scroll !important;
    }
}

.order-popup-column-right{
    padding:0 10px 0 15px;
}
.order-popup-column-left{
    padding: 0 15px 0 10px;
}
/* @media only screen and (min-width: 845px) {
    .nutrition-facts-column {
        height: 375px;
        position: fixed;
        left: 50%;
        overflow-y: scroll;
    }
} */
.order-popup-stripe-inner-wrapper{
    background-color: var(--ash-gray);
    padding: 10px;
    border-radius: 5px;
}
.order-popup-header-title{
    font-family: "Source Sans Pro";
    font-size: 25px;
    line-height: 36px;
    font-weight: 600;
    color: #212529;
    text-align: left;

}
.modal-content-wrapper{
    padding: 0px 22px 10px 30px;
}
.grey-star {
    color: #dfdfdf;
}
.yellow-star {
    color: #FCBA31;
}
.order-popup-rating-star {
    width: 14.5px;
    height: 14px;
    /* color: #FCBA31; */
    margin-left: 5px;
}
.accordian_wrapper{
    background-color: var(--ash-gray) !important;
    border-radius: 5px !important;
}
.accordian_wrapper > div{
    margin: 0 0 !important;
    color: unset;
}
.nutrition-facts-collapse > div > div{
    min-height: unset !important ;
}
.nutrition-facts-collapse > div {
    box-shadow: none;
}
.nutrition-facts-collapse > div >div{
    padding:16px 10px;
}
.nutrition-facts-collapse > div >div + div{
    padding: unset;
}
.nutrition-facts-collapse > div > div > div > div > div > div{
    padding: unset;
} 

.order-popup-additional-info-labels,
.order-popup-additional-optional-labels,
.amount-per-serving-heading{   
    font-style: normal;
    font-size: var(--base);
    line-height: 22px;
    font-family: "Source Sans Pro";
    color: #212529;
}
.amount-per-serving-heading{   
    font-weight: 500;
}

.company-required-field{
    background-color: unset;
}

.order-popup-inner-wrapper{
    padding: 0 10px 8px!important;
    background: var(--ash-gray);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
/* these field are from the mui soo we use important to override the mui classes */
.order-popup-accordian-heading{
    font-size: var(--base) !important;
    line-height: 25px !important;
    font-weight: 600 !important;
    font-family: "Source Sans Pro" !important;
    margin-bottom: 0px !important;
    color: #212529 !important;
}
.order-section-sub-heading{
    font-weight: 600;
    font-size: var(--base);
    line-height: 25px;
    font-family: "Source Sans Pro";
    letter-spacing: 0px;
    color: #212529;
    opacity: 1;
}
.order-popup-labels{
    font-size: var(--large);
    line-height: 25px;
    font-family: "Source Sans Pro";
    font-weight:normal;
}
.amount-per-serving-label{
    font-size: var(--large);
    line-height: 25px;
    font-weight: normal;
    font-family: "Source Sans Pro";
    letter-spacing: 0px;
    color: #999999;
    opacity: 1;
}
.form-control-lg{
    font-size: 1rem;
}

.order-diet-pills {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    /* padding: 10px 20px ; */
    border-radius: 23px;
    font-size: 18px;
    font-weight: 600;
    /* margin: 20px 10px 0 0; */
  }
  .popUp-diet-ing {
    gap: 8px;
  }

  /* .text-eclipses{
        max-height: 100px;
        line-height: 20px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 5;
        -moz-line-clamp: 5;
        -ms-line-clamp: 5;
        line-clamp: 5;
        overflow: hidden;
  } */
  .self-signup-card-details {
    background-color: #fff4cd;
    padding: 15px;
  }
  .self-signup-billing-info {
    font-weight: 600;
    color: #92751f;
  }
  .self-signup-billing-info:hover{
    color: #92751f;
  }
  .billing-info-note{
    color: #a3883a;
  }
  .stripe-heading{
    color: #313439;
    font-weight: bold;
  }