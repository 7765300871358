.timezone-picker {
  display: inline-block;
  /* font: 13px sans-serif; */
  position: relative;
  width: calc(100% - 0px);
}

.timezone-picker-textfield input {
  width: 100%;
  padding: 9px 12px;
  font: inherit;
  box-sizing: border-box;
  outline: 0;
  /* background: #fff; */
  /* border: 1px solid #e6ebec; */
  /* border-radius: 20px */
  height: calc(1.5em + 0.75rem + 2px);
}

.timezone-picker-list {
  background: #fff;
  bottom: 39px !important;
  top: unset !important;
  left: 50%;
  transform: translate(-50%);
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebec;
  margin-top: -1px;
  border-radius: 10px 10px 0px 0px;
  display: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: calc(100% - 0px);
}

.timezone-picker-list-rel {
  position: absolute;
}

.timezone-picker-list-abs {
  position: absolute;
  background-color: white;
  z-index: 3;
  opacity: 0.99;
}

.timezone-picker-list-item {
  color: #444444;
  padding: 5px 12px;
}

.timezone-picker-list-item-active {
  background: #ececec;
  cursor: pointer;
}

.timezone-picker-open .timezone-picker-list {
  display: block;
}

.timezone-picker-selected .timezone-picker-textfield input {
  color: #474747;
}