.rounded-corners{
    border-radius: 16px !important;
    -webkit-border-radius: 16px !important;
    -moz-border-radius: 16px !important;
    -ms-border-radius: 16px !important;
    -o-border-radius: 16px !important;
}
.filters-divider{
    height: 40px;
    width: 2px;
    background-color: #A6B4BA;
    margin: 0 10px;
}
.marketplace-restaurant-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.menu-page-menu-type-toggle {
    margin-right: 75px;
}

.user-list {
    width: 100%;
    height: 300px;
    background-color: #1E4353
}

.scheduler-background {
    border: 0.5px solid white !important;
}

.markePlacePage {
    background-color: white !important;
    border-radius: 25px;
}

.marketPlacePage-parent {
    background-color: #F4F8FB;
}

.marketPlacepageForm {
    margin-top: -60px;
}
.beginNewDelivery {
    color: var(--white);
    font-size: 16px;
    padding: 9px 12px;
    font-weight: 700;
    border-radius: 50px;
}
.carousel-background {
    background-color: #f3fafd;
}
.filters-carousel-scroll {
    position: unset;
}