footer {
  font-size: 0.9rem;
  text-align: center;
  margin: auto;

  /* For sticky footer */

  position: relative;
  /* TODO: This should be absolute for sticky footer to work, fix it later */
  bottom: 0;
  width: 100%;
  height: 100px;
  /* Set the fixed height of the footer here */
  line-height: 30px;
  /* Vertically center the text there */
}

footer a {
  font-size: 0.9rem;
  color: #4c4c4c;
  padding: 0 15px 0 15px;
}